import React from 'react';
import ForestFirstMFE from '../src';
import pkg from '../package.json';

/**
 * @function Root Main function
 * @param props
 * @returns
 */
const Root = props => {
  const shellProps = window.Shell || {};

  return (
    <section
      id={pkg.name}
      style={{ height: '100%', minHeight: 'calc(100vh - 176px)' }}
    >
      <ForestFirstMFE shell={shellProps} {...props} />
    </section>
  );
};

export default Root;

import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { ButtonGroup, Card as VeneerCard } from '@veneer/core';
import { mixins } from '@jarvis/react-portal-addons';

export const Card = styled(VeneerCard)`
  max-width: 294px;
  position: relative;

  ${mixins.xs(`
    max-width: none;
    max-height: none;
    min-width: 230px;
  `)}
`;

export const Header = styled.div`
  width: 100%;
  height: 145px;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: ${primitives.layout.cornerRadius4}px;
  ${primitives.layout.cornerRadius4}px;
  ${primitives.layout.size0}px;
  ${primitives.layout.size0}px;
  background-image: url(${props => props.backgroundImage});
  background-position: center;
`;

export const Body = styled.div`
  margin: ${primitives.layout.size6}px;
  margin-left: ${primitives.layout.size4}px;
  position: relative;
`;

export const Title = styled.div`
  color: ${primitives.color.gray10};
  padding-left: ${primitives.layout.size2}px;
`;

export const Content = styled.div`
  color: ${primitives.color.gray10};
  margin-top: ${primitives.layout.size2}px;
  padding-left: ${primitives.layout.size2}px;
  ${props => (props.hasFooter ? 'padding-bottom: 48px;' : '')}
`;

export const Footer = styled(ButtonGroup)`
  position: absolute;
  bottom: ${primitives.layout.size0}px;
  margin: ${primitives.layout.size6}px;
  margin-left: ${primitives.layout.size4}px; ;
`;

import styled from 'styled-components';
import { IconDocumentRibbon } from '@veneer/core';
import { mixins } from '@jarvis/react-portal-addons';
import primitives from '@veneer/primitives';

export const HeaderIcon = styled(IconDocumentRibbon)`
  min-height: 36px;
  min-width: 36px;
`;

export const Body = styled.div`
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  min-height: 238px;
  flex-wrap: inherit;

  flex-direction: column;
  margin-top: 36px;
  margin-bottom: ${primitives.layout.size3}px;

  & > *:not(:last-child) {
    margin-right: ${primitives.layout.size0}px;
    margin-bottom: 54px;
  }

  ${mixins.lg(`
    flex-direction: row;
    margin-top: 22px;

    & > *:not(:last-child) {
      margin-right: 54px;
    }
  `)}
`;

export const Image = styled.img`
  margin-top: ${primitives.layout.size0}px;

  ${mixins.lg(`
    margin-top: 13px;
  `)}
`;

export const Left = styled.div`
  display: flex;
  flex: 1 0 100px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const PagesText = styled.div`
  text-align: center;
`;

export const Counter = styled.div`
  font-size: 44px;
  color: #53b0a3;
`;

export const Right = styled.div`
  display: flex;
  flex: 1 0 100px;
  flex-direction: column;
`;

export const SubTitle = styled.div`
  color: #53b0a3;
  margin-bottom: ${primitives.layout.size3}px;
`;

export const Description = styled.div`
  color: #3c3c3c;
`;

import React, { useEffect } from 'react';
import { useI18n } from '@jarvis/react-portal-addons';
import PropTypes from 'prop-types';
import HeaderWidget from '../../components/HeaderWidget';
import ImpactWidget from '../../components/ImpactWidget';
import RecycleSuppliesWidget from '../../components/RecycleSuppliesWidget';
import WhatAreWeDoingWidget from '../../components/WhatAreWeDoingWidget';
import {
  Background,
  Bottom,
  Container,
  Footer,
  Header,
  Left,
  Note,
  Right,
  Title,
  Top,
} from './styles';

const MainPage = props => {
  const { t } = useI18n();
  const { scrollToTop } = props;

  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  return (
    <Background>
      <Container data-testid="react-smb-forest-first-page-container">
        <Header>
          <HeaderWidget />
        </Header>
        <Top>
          <ImpactWidget {...props} />
        </Top>

        <Bottom>
          <Left>
            <WhatAreWeDoingWidget {...props} />
          </Left>
          <Right>
            <RecycleSuppliesWidget {...props} />
          </Right>
        </Bottom>

        <Footer>
          <Title
            className="label"
            data-testid="react-smb-forest-first-disclaimers-title"
          >
            {t('disclaimers.header')}
          </Title>
          <Note
            className="caption-small"
            data-testid="react-smb-forest-first-first-disclaimer"
          >
            {t('disclaimers.note1')}
          </Note>
          <Note
            className="caption-small"
            data-testid="react-smb-forest-first-second-disclaimer"
          >
            {t('disclaimers.note2')}
          </Note>
        </Footer>
      </Container>
    </Background>
  );
};

MainPage.propTypes = {
  scrollToTop: PropTypes.func.isRequired,
};

export default MainPage;

import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { NavigationArea, useI18n } from '@jarvis/react-portal-addons';
import {
  BreadcrumbContainer,
  Container,
  ProjectsList,
  ProjectsTitle,
} from './styles';
import ProjectWidget from '../../components/ProjectWidget';
import Images from '../../assets/images';

const projects = [
  {
    image: Images.ffp1,
    header: 'forestFirstProjectsPage.card1.header1',
    body: 'forestFirstProjectsPage.card1.bodyCopy1',
    link: 'forestFirstProjectsPage.card1.link1',
    to: 'https://programs.wcs.org/library/doi/ctl/view/mid/33065/pubid/DMX558900000.aspx',
    'data-testid': 'react-smb-forest-first-first-project-card',
  }, {
    image: Images.ffp2,
    header: 'forestFirstProjectsPage.card2.header2',
    body: 'forestFirstProjectsPage.card2.bodyCopy2',
    link: 'forestFirstProjectsPage.card2.link2',
    to: 'https://www.arborday.org/programs/replanting/action/accomplishments/2014/documents/Michigan.pdf',
    'data-testid': 'react-smb-forest-first-second-project-card',
  }, {
    image: Images.ffp3,
    header: 'forestFirstProjectsPage.card3.header3',
    body: 'forestFirstProjectsPage.card3.bodyCopy3',
    link: 'forestFirstProjectsPage.card3.link3',
    to: 'https://www.treesontheland.com/faqs',
    'data-testid': 'react-smb-forest-first-third-project-card',
  }, {
    image: Images.ffp4,
    header: 'forestFirstProjectsPage.card4.header4',
    body: 'forestFirstProjectsPage.card4.bodyCopy4',
    link: 'forestFirstProjectsPage.card4.link4',
    to: 'https://www.arborday.org/programs/treelineusa/',
    'data-testid': 'react-smb-forest-first-fourth-project-card',
  }, {
    image: Images.ffp5,
    header: 'forestFirstProjectsPage.card5.header5',
    body: 'forestFirstProjectsPage.card5.bodyCopy5',
    link: 'forestFirstProjectsPage.card5.link5',
    to: 'https://www.youtube.com/watch?app=desktop&v=58r4P-mvM0s&list=PL-Pa0VpARGpv4PFXIHXoErUgKQywEI_yb'
      + '&t=5s&ab_channel=ArborDayFoundation%2a',
    'data-testid': 'react-smb-forest-first-fifth-project-card',
  }, {
    image: Images.ffp6,
    header: 'forestFirstProjectsPage.card6.header6',
    body: 'forestFirstProjectsPage.card6.bodyCopy6',
    link: 'forestFirstProjectsPage.card6.link6',
    to: 'https://www.nature.org/en-us/about-us/where-we-work/priority-landscapes/mississippi-river-basin/',
    'data-testid': 'react-smb-forest-first-sixth-project-card',
  }, {
    image: Images.ffp7,
    header: 'forestFirstProjectsPage.card7.header7',
    body: 'forestFirstProjectsPage.card7.bodyCopy7',
    'data-testid': 'react-smb-forest-first-seventh-project-card',
  }, {
    image: Images.ffp8,
    header: 'forestFirstProjectsPage.card8.header8',
    body: 'forestFirstProjectsPage.card8.bodyCopy8',
    link: 'forestFirstProjectsPage.card8.link8',
    to: 'https://www.worldwildlife.org/blogs/sustainability-works/posts/q-a-hp-and-wwf-team-up-to-conserve-forests',
    'data-testid': 'react-smb-forest-first-eighth-project-card',
  },
];

const Projects = props => {
  const { t } = useI18n();
  const {
    scrollToTop,
    shell: {
      v1: {
        navigation,
      } = {
        navigation: null,
      },
    },
    properties: { analyticsScreenPath },
  } = props;

  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  const navigateTo = useCallback(url => {
    if (navigation) {
      navigation.push(url);
    }
  }, [navigation]);

  const breadcrumbsItems = useMemo(() => (
    [
      {
        text: t('breadcrumb.home'),
        onClick: e => {
          e.preventDefault();
          navigateTo('/');
        },
        url: '/',
        'data-testid': 'react-smb-forest-first-home-nav',
      },
      {
        text: t('breadcrumb.forestFirst'),
        onClick: e => {
          e.preventDefault();
          navigateTo('/forestfirst');
        },
        url: '/forestfirst',
        'data-testid': 'react-smb-forest-first-breadcrumb-nav',
      },
      {
        text: t('hpActionsCard.subhead1'),
        onClick: e => {
          e.preventDefault();
        },
        'data-testid': 'react-smb-forest-first-projects-nav',
      },
    ]
  ), [navigateTo, t]);

  const memorizedProjects = useMemo(() => projects.map(p => {
    const l = {
      ...p,
      header: t(p.header),
      body: t(p.body),
      link: t(p.link),
    };
    return (
      <ProjectWidget
        key={p.header} {...l}
        data-testid={p['data-testid']}
        screenPath={analyticsScreenPath}
      />
    );
  }), [t, analyticsScreenPath]);

  return (
    <Container data-testid="react-smb-forest-first-projects-page">

      <BreadcrumbContainer data-testid="react-smb-forest-first-projects-nav">
        <NavigationArea breadcrumbsItems={breadcrumbsItems} />
      </BreadcrumbContainer>

      <ProjectsTitle
        className="title-regular"
        data-testid="react-smb-forest-first-projects-title"
      >
        {t('hpActionsCard.subhead1')}
      </ProjectsTitle>
      <ProjectsList>{memorizedProjects}</ProjectsList>
    </Container>
  );
};

Projects.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any).isRequired,
  scrollToTop: PropTypes.func.isRequired,
  properties: PropTypes.objectOf(PropTypes.any),
};

Projects.defaultProps = {
  properties: { analyticsScreenPath: '' },
};

export default Projects;

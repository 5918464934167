import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { mixins } from '@jarvis/react-portal-addons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${primitives.color.gray0};
`;

export const BreadcrumbContainer = styled.div`
  padding-left: ${primitives.layout.size6}px;

  ${mixins.xs(`
    padding-left: ${primitives.layout.size2}px;
  `)}
`;

export const ProjectsTitle = styled.div`
  color: ${primitives.color.gray10};
  padding-left: ${primitives.layout.size8}px;
  padding-top: ${primitives.layout.size2}px;

  ${mixins.xs(`
    padding-left: ${primitives.layout.size4}px;
  `)}
`;

export const ProjectsList = styled.div`
  padding-top: ${primitives.layout.size8}px;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 988px;
  padding: ${primitives.layout.size4}px;
  box-sizing: content-box;

  & > * {
    margin: ${primitives.layout.size4}px;
  }

  ${mixins.xs(`
    padding: ${primitives.layout.size2}px;
    & > * {
      margin: ${primitives.layout.size2}px;
    }
  `)}
`;

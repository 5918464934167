import React from 'react';
import { Button, ButtonGroup, Card } from '@veneer/core';
import { useI18n } from '@jarvis/react-portal-addons';
import { path } from 'ramda';
import HeaderIcon from './styles';
import {
  CardBody,
  CardBodyDescription,
  CardBodyImage,
  CardBodyLeft,
  CardBodyRight,
  CardContent,
  CardHeader,
  CardHeaderTitle,
  CardSeparator,
} from '../styles';
import Images from '../../assets/images';

const RecycleSuppliesWidget = props => {
  const localization = path(['shell', 'v1', 'localization'], props);
  const { t } = useI18n();
  const country = localization?.country || 'us';
  const language = localization?.language || 'en';
  const selectedLanguage = `${country}-${language}`;
  const openRecycleLink = () => window.open(`https://www.hp.com/${selectedLanguage}/hp-information/recycling/ink-toner.html`, '_blank');

  const cardContent = (
    <CardContent data-testid="react-smb-forest-first-recycle-supplies-card">
      <CardHeader>
        <HeaderIcon />
        <CardHeaderTitle
          className="subtitle-regular"
          dangerouslySetInnerHTML={{ __html: t('recycleSuppliesCard.header1') }}
          data-testid="react-smb-forest-first-recycle-supplies-title"
        />
      </CardHeader>
      <CardSeparator />
      <CardBody>
        <CardBodyLeft>
          <CardBodyImage src={Images.recycle} />
        </CardBodyLeft>
        <CardBodyRight data-testid="react-smb-forest-first-first-description">
          <CardBodyDescription
            className="body"
            data-testid="react-smb-forest-first-second-recycle-supplies-description"
          >
            {t('recycleSuppliesCard.bodyCopy1')}
          </CardBodyDescription>
          <ButtonGroup orientation="horizontal">
            <Button
              appearance="ghost"
              onClick={openRecycleLink}
              small
              data-testid="react-smb-forest-first-recycle-cartridges-button"
            >
              {t('recycleSuppliesCard.primaryButton')}
            </Button>
          </ButtonGroup>
        </CardBodyRight>
      </CardBody>
    </CardContent>
  );

  return (
    <Card
      appearance="dropShadow"
      content={cardContent}
    />
  );
};

export default RecycleSuppliesWidget;

import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { mixins } from '@jarvis/react-portal-addons';
import Images from '../../assets/images';

export const Container = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  align-items: center;
  padding: ${primitives.layout.size4}px;

  & > *:not(:last-child) {
    margin-bottom: ${primitives.layout.size6}px;
  }

  ${mixins.sm(`
    padding: ${primitives.layout.size8}px;
  `)}
`;

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${primitives.layout.size4}px;
  height: 42px;
`;

export const Header = styled.div`
  width: 100%;
  min-height: 378px;
  max-width: 956px;
`;

export const Background = styled.div`
  background-image: url(${Images.forestFirstHeader});
  background-position: top center;
  background-repeat: no-repeat;
  min-height: 573px;
  max-width: 1660px;
  width: 100%;
  padding: ${primitives.layout.size0}px;
  margin: ${primitives.layout.size0}px;
`;

export const Top = styled.div`
  display: flex;
  flex: 0 1 auto;
  width: 100%;
  min-height: 317px;
  max-width: 956px;
`;

export const Bottom = styled.div`
  display: flex;
  flex: 0 1 auto;
  width: 100%;
  max-width: 956px;

  flex-direction: column;
  & > *:not(:last-child) {
    margin-right: ${primitives.layout.size0}px;
    margin-bottom: ${primitives.layout.size6}px;
  }

  ${mixins.lg(`
    flex-direction: row;
    & > *:not(:last-child) {
      margin-right: ${primitives.layout.size6}px;
    }
  `)}
`;

export const Left = styled.div`
  width: 100%;

  ${mixins.lg(`
    max-width: 480px;
  `)}
`;

export const Right = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: ${primitives.layout.size6}px;
  }

  ${mixins.lg(`
    max-width: 480px;
  `)}
`;

export const Footer = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  width: 100%;
  max-width: 956px;
`;

export const Title = styled.div`
  padding: ${primitives.layout.size0}px;
`;

export const Note = styled.div`
  padding-top: ${primitives.layout.size4}px;
`;

import forest from './forest.svg';
import forestFirstHeader from './forest-first-header.jpg';
import forestFirstLogoWhite from './forest-first-logo-white.svg';
import checked from './checked.svg';
import forestFirstLogoGreen from './forest-first-logo-green.svg';
import forestGlobal from './forest-global.svg';
import recycle from './recycle.svg';
import ffp1 from './ffp-1.jpg';
import ffp2 from './ffp-2.jpg';
import ffp3 from './ffp-3.jpg';
import ffp4 from './ffp-4.jpg';
import ffp5 from './ffp-5.jpg';
import ffp6 from './ffp-6.jpg';
import ffp7 from './ffp-7.jpg';
import ffp8 from './ffp-8.jpg';

const Images = {
  checked,
  recycle,
  ffp1,
  ffp2,
  ffp3,
  ffp4,
  ffp5,
  ffp6,
  ffp7,
  ffp8,
  forest,
  forestFirstHeader,
  forestFirstLogoGreen,
  forestFirstLogoWhite,
  forestGlobal,
};

export default Images;

import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Card } from '@veneer/core';
import { useI18n } from '@jarvis/react-portal-addons';
import HeaderIcon from './styles';
import Images from '../../assets/images';
import {
  CardBody,
  CardBodyDescription,
  CardBodyImage,
  CardBodyLeft,
  CardBodyRight,
  CardBodyTitle,
  CardContent,
  CardHeader,
  CardHeaderTitle,
  CardSeparator,
  WidgetContainer,
} from '../styles';

const WhatAreWeDoingWidget = props => {
  const { t } = useI18n();
  const {
    shell: {
      v1: {
        navigation,
      } = {
        navigation: null,
      },
    },
  } = props;

  const onProjectsClick = () => {
    if (navigation) {
      navigation.push('/forestfirst/projects');
    }
  };

  const openImpactLink = () => window.open('https://www.hp.com/us-en/hp-information/sustainable-impact.html', '_blank');

  return (
    <WidgetContainer data-testid="react-smb-forest-first-wawd-card">
      <Card
        appearance="dropShadow"
        content={(
          <CardContent>
            <CardHeader>
              <HeaderIcon />
              <CardHeaderTitle className="subtitle-regular" data-testid="react-smb-forest-first-wawd-title">
                {t('hpActionsCard.header')}
              </CardHeaderTitle>
            </CardHeader>
            <CardSeparator />
            <CardBody>
              <CardBodyLeft>
                <CardBodyImage src={Images.forestFirstLogoGreen} alt="forestfirstlogogreen" />
              </CardBodyLeft>
              <CardBodyRight>
                <CardBodyTitle className="subtitle-regular" data-testid="react-smb-forest-first-projects-title">
                  {t('hpActionsCard.subhead1')}
                </CardBodyTitle>
                <CardBodyDescription
                  className="body"
                  data-testid="react-smb-forest-first-projects-description"
                  dangerouslySetInnerHTML={{ __html: t('hpActionsCard.bodyCopy1') }}
                />
                <ButtonGroup orientation="horizontal">
                  <Button
                    appearance="ghost"
                    onClick={onProjectsClick}
                    small
                    data-testid="react-smb-forest-first-view-projects-button"
                  >
                    {t('hpActionsCard.primaryButton1')}
                  </Button>
                </ButtonGroup>
              </CardBodyRight>
            </CardBody>
            <CardBody>
              <CardBodyLeft>
                <CardBodyImage src={Images.forestGlobal} alt="forestglobal" />
              </CardBodyLeft>
              <CardBodyRight>
                <CardBodyTitle
                  className="subtitle-regular"
                  data-testid="react-smb-forest-first-explore-sustainable-impact-title"
                >
                  {t('hpActionsCard.subhead2')}
                </CardBodyTitle>
                <CardBodyDescription
                  className="body"
                  data-testid="react-smb-forest-first-explore-sustainable-impact-description"
                >
                  {t('hpActionsCard.bodyCopy2')}
                </CardBodyDescription>
                <ButtonGroup orientation="horizontal">
                  <Button
                    appearance="ghost"
                    onClick={openImpactLink}
                    small
                    data-testid="react-smb-forest-first-explore-impact-button"
                  >
                    {t('hpActionsCard.primaryButton2')}
                  </Button>
                </ButtonGroup>
              </CardBodyRight>
            </CardBody>
          </CardContent>
        )}
      />
    </WidgetContainer>
  );
};

WhatAreWeDoingWidget.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default WhatAreWeDoingWidget;

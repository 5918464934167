import React from 'react';
import { Button, ButtonGroup } from '@veneer/core';
import { useI18n } from '@jarvis/react-portal-addons';
import Images from '../../assets/images';
import {
  Container,
  IconHeader,
  SubTitle,
  Title,
} from './styles';

const HeaderWidget = () => {
  const { t } = useI18n();
  const openLearnMore = () => window.open('https://hp.com/forestfirst', '_blank');

  return (
    <Container data-testid="react-smb-forest-first-header-widget">
      <IconHeader
        src={Images.forestFirstLogoWhite}
        alt={t('breadcrumb.forestFirst')}
        data-testid="react-smb-forest-first-header-widget-icon"
      />
      <Title className="title-medium" data-testid="react-smb-forest-first-header-title">
        {t('banner.bannerHeader')}
      </Title>
      <SubTitle data-testid="react-smb-forest-first-header-description" className="subtitle-regular">
        {t('banner.bannerBodyCopy')}
      </SubTitle>
      <ButtonGroup orientation="horizontal">
        <Button onClick={openLearnMore} data-testid="react-smb-forest-first-header-learn-more-button">
          {t('banner.bannerButton')}
        </Button>
      </ButtonGroup>
    </Container>
  );
};

export default HeaderWidget;

import primitives from '@veneer/primitives';
import styled from 'styled-components';

export const WidgetContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const CardContent = styled.div`
  padding: ${primitives.layout.size6}px;
`;

export const CardSeparator = styled.div`
  height: 1px;
  margin: ${primitives.layout.size0}px; -${primitives.layout.size6}px;
  border-bottom: 1px solid ${primitives.color.gray2};
`;

export const CardHeader = styled.div`
  display: flex;
  flex: 0 1px auto;
  flex-direction: row;
  align-items: center;
  padding-bottom: ${primitives.layout.size6}px;

  & > *:not(:last-child) {
    margin-right: ${primitives.layout.size3}px;
  }
`;

export const CardHeaderTitle = styled.div`
  color: ${primitives.color.gray10};

  > sup {
    font-size: ${primitives.typography.size4};
    line-height: ${primitives.typography.lineHeight3};
    vertical-align: super;
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex: 0 1 auto;
  align-items: flex-start;
  margin-top: ${primitives.layout.size6}px;

  &:not(:last-child) {
    padding-bottom: ${primitives.layout.size8}px;
    border-bottom: 1px solid ${primitives.color.gray2};
  }
`;

export const CardBodyLeft = styled.div`
  width: 64px;
`;

export const CardBodyImage = styled.img`
  margin-top: ${primitives.layout.size0}px;
`;

export const CardBodyRight = styled.div`
  display: flex;
  flex: 1 0 100px;
  flex-direction: column;
`;

export const CardBodyTitle = styled.div`
  color: ${primitives.color.gray10};
  padding-left: ${primitives.layout.size2}px;
`;

export const CardBodyDescription = styled.div`
  color: ${primitives.color.gray10};
  padding-bottom: ${primitives.layout.size2}px;
  padding-left: ${primitives.layout.size2}px;

  > sup {
    font-size: ${primitives.typography.size1};
    line-height: ${primitives.typography.lineHeight1};
    vertical-align: super;
  }
`;

import styled from 'styled-components';
import primitives from '@veneer/primitives';

export const Container = styled.div`
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${primitives.layout.size3}px ${primitives.layout.size0}px;
`;

export const IconHeader = styled.img`
  width: 97px;
  height: 93px;
  display: block;
`;

export const Title = styled.div`
  margin-top: ${primitives.layout.size4}px;
  color: ${primitives.color.white};
  text-align: center;
  max-width: 75%;
`;

export const SubTitle = styled.div`
  margin: ${primitives.layout.size6}px ${primitives.layout.size0}px;
  color: ${primitives.color.white};
  text-align: center;
  max-width: 90%;
`;

import React, { useCallback, useRef } from 'react';
import { createBrowserHistory } from 'history';
import { path } from 'ramda';
import { Route, Router, Switch } from 'react-router-dom';
import { RootProvider } from '@jarvis/react-portal-addons';
import MainPage from './pages/MainPage';
import ProjectsPage from './pages/Projects';
import { Content } from './styles';
import resources from './assets/locale/index';

export const history = createBrowserHistory();

const App = props => {
  const localization = path(['shell', 'v1', 'localization'], props);
  const navigation = path(['shell', 'v1', 'navigation'], props);

  const ref = useRef(null);

  const scrollToTop = useCallback(() => {
    ref.current.scrollIntoView();
  }, [ref]);

  return (
    <Content ref={ref}>
      <RootProvider
        localization={localization}
        resources={resources}
      >
        <Router history={navigation || history}>
          <Switch>
            <Route path="/forestfirst/projects">
              <ProjectsPage {...props} scrollToTop={scrollToTop} />
            </Route>
            <Route path="/forestfirst">
              <MainPage {...props} scrollToTop={scrollToTop} />
            </Route>
          </Switch>
        </Router>
      </RootProvider>
    </Content>
  );
};

export default App;
